import { type } from "@testing-library/user-event/dist/type";
import { axiosClouth } from "../../config/apiConfig";

import {
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  GET_ADDRESS_FAILURE,
  GET_ADDRESS_REQUEST,
  GET_ADDRESS_SUCCESS,
  GET_ORDER_BY_ID_FAILURE,
  GET_ORDER_BY_ID_REQUEST,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ORDER_HISTORY_FAILURE,
  GET_ORDER_HISTORY_REQUEST,
  GET_ORDER_HISTORY_SUCCESS,
} from "./ActionType";

export const createOrder = (reqData) => async (dispatch) => {
  console.log("req data", reqData);

  dispatch({ type: CREATE_ORDER_REQUEST });
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${reqData?.reqJWT}`,
      },
    };
    const { data } = await axiosClouth.post(
      `/api/orders/`,
      reqData.addressSend,
      config
    );
    if (data.id) {
      reqData.navigate({ search: `step=3&order_id=${data.id}` });
    }
    console.log("created order - ", data);
    dispatch({
      type: CREATE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_ORDER_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createOrderWithOldAddress = (reqData) => async (dispatch) => {
  console.log("req data", reqData);

  dispatch({ type: CREATE_ORDER_REQUEST });
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${reqData?.reqJWT}`,
      },
    };
    const { data } = await axiosClouth.post(
      `/api/orders/oldShippingAddress`,
      reqData.filteredAddress,
      config
    );
    if (data.id) {
      reqData.navigate({ search: `step=3&order_id=${data.id}` });
    }
    console.log("created order - ", data);
    dispatch({
      type: CREATE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_ORDER_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOrderById = (reqData) => async (dispatch) => {
  console.log("get order req ", reqData.orderId);

  try {
    dispatch({ type: GET_ORDER_BY_ID_REQUEST });

    const { data } = await axiosClouth.get(`/api/orders/${reqData.orderId}`, {
      headers: {
        Authorization: `Bearer ${reqData?.reqJWT}`,
      },
    });
    console.log("order by id ", data);
    dispatch({
      type: GET_ORDER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log("catch ", error);
    dispatch({
      type: GET_ORDER_BY_ID_FAILURE,
      payload: error.message,
    });
  }
};

export const getOrderHistory = (reqData) => async (dispatch, getState)=>{

  try {
    dispatch({ type: GET_ORDER_HISTORY_REQUEST });

    const config = {
        headers: {
            Authorization: `Bearer ${reqData?.reqJWT}`,
          },
    }
    const { data } = await axiosClouth.get(`api/orders/user`, {
      headers: {
        Authorization: `Bearer ${reqData?.reqJWT}`,
      },
    });
    console.log("order history --------- ", data);
    dispatch({ 
        type: GET_ORDER_HISTORY_SUCCESS, 
        payload: data 
    });
  } catch (error) {
    dispatch({ 
        type: GET_ORDER_HISTORY_FAILURE, 
        payload: 
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAddressList = (reqData) => async (dispatch) =>{

    try{
        dispatch({type: GET_ADDRESS_REQUEST});

        const {data} = await axiosClouth.get(`/api/address/addressList?cartId=${reqData.cartId}`,{
          headers: {
            Authorization: `Bearer ${reqData?.reqJWT}`,
          },
        });
        console.log("Address-",data);
        dispatch({type: GET_ADDRESS_SUCCESS, payload: data });
        
    }catch (error) {
      dispatch({type: GET_ADDRESS_FAILURE, payload: error.message});
    }
};
