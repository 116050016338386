import { Padding } from '@mui/icons-material'
import { Alert, Button, Grid, Snackbar, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUser, vrfyAccts } from '../../State/Auth/Action'

const VrfyForm = () => {
    console.log("JWT3- ",localStorage.getItem("jwt"));
    const navigate=useNavigate();
    const dispatch=useDispatch(); 
    const [openSnackBar,setOpenSnackBar]=useState(false);
    const {auth}=useSelector(store=>store);
    const getEmailId=localStorage.getItem("TempEmailId");
    const handleClose=()=>setOpenSnackBar(false);

    const handleSubmit=(event)=>{
        event.preventDefault()

        const data=new FormData(event.currentTarget);

        const userData={
            emailid:      data.get("emailid"),
            numotp:       data.get("numotp"),
            strotp:       data.get("strotp")
        }
        const response=dispatch(vrfyAccts(userData));
    }
    useEffect(()=>{
        if(auth?.vrfy==='Error'){
            alert(`OTP is wrong...!`);
        }else if(auth?.vrfy==='Success'){
            console.log("vrfyResult-", auth?.vrfy);
            alert(`Registration is ${auth?.vrfy}`);
        }
    },[auth?.vrfy]);

    /*useEffect(()=>{
        const data={
            reqJWT: auth?.jwt?.access_token
        }
        dispatch(getUser(data));
    },[auth?.jwt?.access_token]);*/

  return (
    <div>
      <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <TextField
                        required
                        id='emailid'
                        name='emailid'
                        label="Email Id"
                        value={getEmailId}
                        inputProps={{readOnly: true}}
                        fullWidth
                        autoComplete='email'
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        id='strotp'
                        name='strotp'
                        label="Email OTP"
                        fullWidth
                        autoComplete='Email OTP'
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        id='numotp'
                        name='numotp'
                        label="Mobile OTP"
                        fullWidth
                        autoComplete='Mobile OTP'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        className='bg-[#9155FD] w-full'
                        type='submit'
                        variant='contained'
                        size='large'
                        sx={{padding:".8rem 0", bgcolor:"#9155FD"}}
                    >
                        Submit
                    </Button>
                </Grid>

            </Grid>
      </form>

      <div className='flex justify-center flex-col items-center'>
        <div className='py-3 flex items-center'>
            <p>if you don't have account ?</p>
            <Button onClick={()=>navigate("/login")} className='ml-5' size='small'>Login here</Button>
        </div>
      </div>
      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {auth.error?auth.error:auth.user?"Register Success":""}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default VrfyForm
