import { Padding } from '@mui/icons-material'
import { Alert, Button, Dialog, DialogActions, DialogTitle, Grid, Snackbar, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUser, register } from '../../State/Auth/Action'

const RegisterForm = () => {
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const [openSnackBar,setOpenSnackBar]=useState(false);
    const jwt=localStorage.getItem("jwt");
    const {auth}=useSelector(store=>store)
    const handleClose=()=>setOpenSnackBar(false);
    const [open, setOpen] = useState(false);

    /*useEffect(()=>{
        console.log("CheckStatus-",auth?.jwt);
        setCurrstate(auth?.jwt);
        if(jwt){
            dispatch(getUser())
        }
    },[jwt, auth?.jwt]);*/

    useEffect(() => {
        if (auth.user || auth.error) setOpenSnackBar(true)
    }, [auth.user]);

    const handleErrorDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleSubmit=(event)=>{
        event.preventDefault()

        const data=new FormData(event.currentTarget);

        const userData={
            firstname:          data.get("firstName"),
            lastname:           data.get("lastName"),
            mobile:             data.get("mobile"),
            emailid:            data.get("email"),
            password:           data.get("password"),
            matchingPassword:   data.get("matchingPassword")
        }
        localStorage.setItem("TempEmailId",data.get("email"));
        const response = dispatch(register(userData));
    }

    useEffect(()=>{
        if(auth?.jwt==='Error'){
            handleErrorDialogOpen();
        }else if(auth?.jwt==='Success') {
            navigate("/VrfyForm");
        }
    },[auth?.jwt]);
    
  return (
    <div>

      <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>

                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id='firstName'
                        name='firstName'
                        label="First Name"
                        fullWidth
                        autoComplete='given-name'
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id='lastName'
                        name='lastName'
                        label="Last Name"
                        fullWidth
                        autoComplete='given-name'
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        id='mobile'
                        name='mobile'
                        label="Mobile Number"
                        fullWidth
                        autoComplete='mobile'
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        id='email'
                        name='email'
                        label="Email Id"
                        fullWidth
                        autoComplete='email'
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        id='password'
                        name='password'
                        label="Password"
                        type="password"
                        fullWidth
                        autoComplete='password'
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required
                        id='matchingPassword'
                        name='matchingPassword'
                        label="Confirm Password"
                        type="password"
                        fullWidth
                        autoComplete='matchingPassword'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        className='bg-[#9155FD] w-full'
                        type='submit'
                        variant='contained'
                        size='large'
                        sx={{padding:".8rem 0", bgcolor:"#9155FD"}}
                    >
                        Register
                    </Button>
                </Grid>

            </Grid>
      </form>
      <div className='flex justify-center flex-col items-center'>
        <div className='py-3 flex items-center'>
            <p>After registration. Plz Verify emailid & mobile no.</p>
            <Button onClick={()=>navigate("/VrfyForm")} className='ml-5' size='small'>Verfy</Button>
        </div>
      </div>
      <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {auth.error?auth.error:auth.user?"Register Success":""}
        </Alert>
      </Snackbar>

        <Dialog open={open} onClose={handleDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
            {"Registration Error...!"}
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleDialogClose} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

export default RegisterForm
