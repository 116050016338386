import { Fragment, useEffect, useState } from 'react';
import { Dialog, Popover, Tab, Transition } from '@headlessui/react';
import { 
  Bars3Icon, 
  MagnifyingGlassIcon, 
  ShoppingBagIcon, 
  XMarkIcon,
 } from '@heroicons/react/24/outline';

 import { Avatar, Button, Menu, MenuItem } from '@mui/material';
 import { deepPurple } from "@mui/material/colors";
import { navigation } from '../../../config/navigationData';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthModal from '../../Auth/AuthModal';
import Authorize from '../../../State/Auth/Authorize';
import { generateCodeChallenge, generateCodeVerifer } from '../../../State/Auth/pkce';
import { mainAxios } from '../../../config/apiConfig';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../State/store';
import { getUser, logout } from '../../../State/Auth/Action';


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('unauthenticated');
  const navigate=useNavigate();
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  //console.log("JWT21- ",localStorage.getItem("jwt11"));
  //const jwt11=localStorage.getItem("jwt11");
  //console.log("JWT311- ",jwt11);
  const {auth}=useSelector(store=>store)
  const dispatch=useDispatch();
  const location=useLocation();

  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpenAuthModal(true);
  };

  const handleClose = () => {
    setOpenAuthModal(false);
  };

  const handleCategoryClick = (category, section, item, close) => {
    navigate(`/${category.id}/${section.id}/${item.id}`);
    close();
  };

  useEffect(()=>{
    //console.log("JWT31- ",localStorage.getItem(auth?.jwt?.access_token));
    //console.log("JWT312- ",jwt11);
    //console.log("JWT412- ",auth?.jwt);
    const userData={
        emailid: localStorage.getItem("emailid"),
        accessToken: auth?.jwt?.access_token
    }
    //const emailid=localStorage.getItem("emailid");
    console.log("Emailid Get- ",userData);
    //console.log("User- ",auth?.user?.firstname);
    if(auth?.jwt?.access_token){
        dispatch(getUser(userData))
    }
  },[auth?.jwt?.access_token, auth?.jwt])

  /*useEffect(()=>{
    if(auth.user){
      handleClose()
    }
    if(location.pathname==="/login" || location.pathname==="/register"){
      navigate(-1)
    }
  },[auth.user])*/

  const hnadleLogout=()=>{
    dispatch(logout(auth?.jwt))
    handleCloseUserMenu()
  }
  
  const handleGoAdmin=()=>{
    navigate("/admin")
  }
  
  const lgSubmit = async() => {
    if(status==='unauthenticated'){
      const state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      const codeVerifier = generateCodeVerifer(); 
      if (typeof window !== "undefined") {
        window.localStorage.setItem('code_Verifier',codeVerifier);
        window.localStorage.setItem('State',state);
      }
      try{
        //const url2 = 'http://localhost:8080/api/v1/auth/saveCodeVrfy';
        const data = { "stateKey": state, "codeVrfyKey": codeVerifier };
        // Specifying headers in the config object
        const config = { 'content-type': 'application/json' };

        const response = await mainAxios.post('/api/v1/auth/saveCodeVrfy', data, config);
      }catch(error){
        console.error(error);
      }
      //console.log("GenCodeVrfy-",localStorage.getItem('code_Verifier'));
      //console.log("State-",localStorage.getItem('State'));
      const codeChallenge = generateCodeChallenge();
      sessionStorage.setItem('code_Challenge',codeChallenge);
      setStatus('authenticated');
      window.location.href = Authorize();
    }else{
      alert("Log in..!");
    }
  };

  /*const lgUrlfry = async() => {
    await signOut({
      callbackUrl: "/api/auth/logout",
    });
    window.location.href="http://localhost:9000/logout?redirect_uri=http://localhost:3000";
  };*/

  return (
    <div className="bg-white pb-10">
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" open={open} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25"/>
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            >

            <Dialog.Panel
              className="relative flex w-full max-w-xs transform flex-col overflow-y-auto 
                bg-white pb-12 shadow-xl">

              <div className="flex px-4 pb-2 pt-5">
                <button
                  type="button"
                  className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              <Tab.Group as="div" className="mt-2">
                <div className="border-b border-gray-200">
                  <Tab.List className="-mb-px flex space-x-8 px-4">
                    {navigation.categories.map((category) => (
                      <Tab
                        key={category.name}
                        className={({ selected }) =>
                          classNames(
                            selected ? "border-indigo-600 text-indigo-600"
                             : "border-transparent text-gray-900",
                            "flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium border-none"
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
                <Tab.Panels as={Fragment}>
                  {navigation.categories.map((category) => (
                    <Tab.Panel key={category.name} className="space-y-10 px-4 pb-8 pt-10">
                      <div className="grid grid-cols-2 gap-x-4">
                        {category.featured.map((item) => (
                          <div key={item.name} className="group relative text-sm">
                            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                              <img src={item.imageSrc} alt={item.imageAlt} className="object-cover object-center" />
                            </div>
                            <a href={item.href} className="mt-6 block font-medium text-gray-900">
                              <span className="absolute inset-0 z-10" aria-hidden="true" />
                              {item.name}
                            </a>
                            <p aria-hidden="true" className="mt-1">
                              Shop now
                            </p>
                          </div>
                        ))}
                      </div>                     
                      {category.sections.map((section) => (
                        <div key={section.name}>
                          <p id={`${category.id}-${section.id}-heading-mobile`} className="font-medium text-gray-900">
                            {section.name}
                          </p>
                          <ul
                            role="list"
                            aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                            className="mt-6 flex flex-col space-y-6"
                          >
                            {section.items.map((item) => (
                              <li key={item.name} className="flow-root">
                                <p onClick={()=>
                                  handleCategoryClick(
                                    category,
                                    section,
                                    item
                                  )
                                } className="-m-2 block p-2 text-gray-500">
                                  {item.name}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>

              <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                {navigation.pages.map((page) => (
                  <div key={page.name} className="flow-root">
                    <a href={page.href} className="-m-2 block p-2 font-medium text-gray-900">
                      {page.name}
                    </a>
                  </div>
                ))}
              </div>

              <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                <div className="flow-root">
                  <a href="#" onClick={lgSubmit} className="-m-2 block p-2 font-medium text-gray-900">
                    Sign in
                  </a>
                </div>
                <div className="flow-root">
                  <a href="#" onClick={handleOpen} className="-m-2 block p-2 font-medium text-gray-900">
                    Registration
                  </a>
                </div>
              </div>

              <div className="border-t border-gray-200 px-4 py-6">
                <a href="#" className="-m-2 flex items-center p-2">
                  <img
                    src="https://tailwindui.com/img/flags/flag-canada.svg"
                    alt=""
                    className="block h-auto w-5 flex-shrink-0"
                  />
                  <span className="ml-3 block text-base font-medium text-gray-900">CAD</span>
                  <span className="sr-only">, change currency</span>
                </a>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root> 

      <header className="relative bg-white">
        <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          e-Nearmart in your location.
        </p>

        <nav aria-label="Top" className="mx-auto">
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center px-11">
              <button
                type="button"
                className="rounded-md bg-white p-2 text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
               
                  <span className="sr-only">Pratik IT Services, Giridih</span>
                  <img
                    className="h-8 w-8 mr-2"
                    src="https://www.enearmart.com/logo.png"
                    alt=""
                  />
             
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch z-10">
                <div className="flex h-full space-x-8">
                  {navigation.categories.map((category) => (
                    <Popover key={category.name} className="flex">
                      {({ open, close }) => (
                        <>
                          <div className="relative flex">
                            <Popover.Button
                              className={classNames(
                                open
                                  ? 'border-indigo-600 text-indigo-600'
                                  : 'border-transparent text-gray-700 hover:text-gray-800',
                                'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out',
                              )}
                            >
                              {category.name}
                            </Popover.Button>
                          </div>
                          <Transition 
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            >      
                          <Popover.Panel
                            transition
                            className="absolute inset-x-0 top-full text-sm text-gray-500 transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                          >
                            {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                            <div className="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                            <div className="relative bg-white">
                              <div className="mx-auto max-w-7xl px-8">
                                <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-16">
                                  <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                    {category.featured.map((item) => (
                                      <div key={item.name} className="group relative text-base sm:text-sm">
                                        <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                          <img
                                            src={item.imageSrc}
                                            alt={item.imageAlt}
                                            className="object-cover object-center"
                                          />
                                        </div>
                                        <a href={item.href} className="mt-6 block font-medium text-gray-900">
                                          <span className="absolute inset-0 z-10" aria-hidden="true" />
                                          {item.name}
                                        </a>
                                        <p aria-hidden="true" className="mt-1">
                                          Shop now
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="row-start-1 grid grid-cols-3 gap-x-8 gap-y-10 text-sm">
                                    {category.sections.map((section) => (
                                      <div key={section.name}>
                                        <p id={`${section.name}-heading`} className="font-medium text-gray-900">
                                          {section.name}
                                        </p>
                                        <ul
                                          role="list"
                                          aria-labelledby={`${section.name}-heading`}
                                          className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                        >
                                          {section.items.map((item) => (
                                            <li key={item.name} className="flex">
                                              <p
                                                onClick={()=>
                                                  handleCategoryClick(
                                                    category,
                                                    section,
                                                    item,
                                                    close
                                                  )
                                                }
                                                className="cursor-pointer hover:text-gray-800">
                                                {item.name}
                                              </p>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                        </>
                      )}
                    </Popover>
                  ))}

                  {navigation.pages.map((page) => (
                    <a
                      key={page.name}
                      href={page.href}
                      className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                    >
                      {page.name}
                    </a>
                  ))}
                </div>
              </Popover.Group>

              <div className="ml-auto flex items-center">
                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  {auth?.user?.firstname ? (
                    <div>
                      <Avatar
                      className="text-white"
                      onClick={handleUserClick}
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      // onClick={handleUserClick}
                      sx={{
                        bgcolor: deepPurple[500],
                        colorL: "white",
                        cursor: "pointer",
                      }}
                    >
                      {auth?.user?.firstname[0].toUpperCase()}
                  </Avatar>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openUserMenu}
                    onClose={handleCloseUserMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    >
                      <MenuItem onClick={handleCloseUserMenu}>
                        Profile
                      </MenuItem>
                      <MenuItem onClick={()=>navigate("/account/order")}>
                        My Orders
                      </MenuItem>
                      <MenuItem onClick={hnadleLogout}>
                        Logout
                      </MenuItem>
                    </Menu>
                    </div>
                  ): (
                    <div>
                      <Button 
                        onClick={lgSubmit}
                        className="text-sm font-medium text-gray-700 hover:text-gray-800"
                        >
                          Sign in
                      </Button>
                      <Button 
                        onClick={handleOpen}
                        className="text-sm font-medium text-gray-700 hover:text-gray-800"
                        >
                          Registration
                      </Button>
                      <Button 
                        onClick={handleGoAdmin}
                        className="text-sm font-medium text-gray-700 hover:text-gray-800"
                        >
                          Admin Panel
                      </Button>
                    </div>
                  )}
                </div>

                {/* Search */}
                <div className="flex lg:ml-6">
                  <a href="#" className="p-2 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Search</span>
                    <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
                  </a>
                </div>

                {/* Cart */}
                <div className="ml-4 flow-root lg:ml-6">
                  <Button className="group -m-2 flex items-center p-2">
                    <ShoppingBagIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="ml-2 text-sm font-medium text-gray-700
                     group-hover:text-gray-800">
                      2
                    </span>
                    <span className="sr-only">items in cart, view bag</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <AuthModal handleClose={handleClose} open={openAuthModal}/>
    </div>
  );
}
