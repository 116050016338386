import React from 'react'

const AddressCardSelected = ({address}) => {
  return (
    <div className='space-y-3'>
      <p></p>
      <p className='font-semibold'>{address?.firstName+" "+address?.lastName}</p>
      <p>{"At-"}{address?.streetAddress}</p>
      <p>{"District-"}{address?.city}{"-"}{address?.pincode}</p>
      <p>{"State-"}{address?.state}</p>
      <div className='space-y-1'>
        <p className='font-semibold'>Phone Number</p>
        <p>{address?.mobile}</p>
      </div>
      <p>-----------------------------------------------</p>
    </div>
  )
}

export default AddressCardSelected
