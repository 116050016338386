import React from 'react'

const DemoAdmin = () => {
  return (
    <div>
      DemoAdmin
    </div>
  )
}

export default DemoAdmin
