import { mainAxios, axiosClouth, axiosAuth } from '../../../src/config/apiConfig'
import { Buffer } from "buffer";
import {  
    REGISTER_FAILURE, 
    REGISTER_REQUEST, 
    REGISTER_SUCCESS,
    LOGIN_FAILURE, 
    LOGIN_REQUEST, 
    LOGIN_SUCCESS, 
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    LOGOUT,
    GET_ALL_USER_REQUEST,
    GET_ALL_USER_SUCCESS,
    GET_ALL_USER_FAILURE,
    VRFYACCTS_REQUEST,
    VRFYACCTS_SUCCESS,
    VRFYACCTS_FAILURE,
    CHANGE_USER_PROFILE_REQUEST,
    CHANGE_USER_PROFILE_SUCCESS,
    CHANGE_USER_PROFILE_FAILURE} from "./ActionType"

//const accessToken=localStorage.getItem("jwt");
const registerRequest=()=>({type:REGISTER_REQUEST});
const registerSuccess=(user)=>({type:REGISTER_SUCCESS, payload:user});
const registerFailure=(error)=>({type:REGISTER_FAILURE, payload:error});

export const register=(userData)=>async (dispatch)=>{

    dispatch(registerRequest())
    
    try{
        const response=await mainAxios.post(`/api/v1/auth/register`,userData)
        const user=response.data;
        if(user==='Success'){
            localStorage.setItem("Result",user);
        }
        console.log("user- ", user)
        dispatch(registerSuccess(user))
    }catch (error) {
        dispatch(registerFailure(error.message))
    }
}

const vrfyAcctsRequest=()=>({type:VRFYACCTS_REQUEST});
const vrfyAcctsSuccess=(user)=>({type:VRFYACCTS_SUCCESS, payload:user});
const vrfyAcctsFailure=(error)=>({type:VRFYACCTS_FAILURE, payload:error});

export const vrfyAccts=(userData)=>async (dispatch)=>{

    dispatch(vrfyAcctsRequest())
    console.log("VrfyData-",userData);
    try{
        const response=await mainAxios.post(`/api/v1/auth/verifyOTP`,userData)
        const user=response.data;
        if(user==='Success'){
            localStorage.setItem("Result",user);
        }
        console.log("user- ", user)
        dispatch(vrfyAcctsSuccess(user))
    }catch (error) {
        dispatch(vrfyAcctsFailure(error.message))
    }
}

const loginRequest=()=>({type:LOGIN_REQUEST});
const loginSuccess=(user)=>({type:LOGIN_SUCCESS, payload:user});
const loginFailure=(error)=>({type:LOGIN_FAILURE, payload:error});

export const login=(userData)=>async (dispatch)=>{

    dispatch(loginRequest())
    
    try{
        //const response=await mainAxios.post(`/api/v1/auth/register`,userData)
        //const user=response.data;
        //if(userData.access_token){
            //localStorage.setItem("jwt",userData.access_token);
        //}
        //console.log("user- ", userData)
        dispatch(loginSuccess(userData))

    }catch (error) {
        dispatch(loginFailure(error.message))
    }
    
}

const chgUserProfileRequest=()=>({type:CHANGE_USER_PROFILE_REQUEST});
const chgUserProfileSuccess=(user)=>({type:CHANGE_USER_PROFILE_SUCCESS, payload:user});
const chgUserProfileFailure=(error)=>({type:CHANGE_USER_PROFILE_FAILURE, payload:error});

export const chgUserProfile=(reqData)=>async (dispatch)=>{
    console.log("UserProfile=",reqData)
    dispatch(chgUserProfileRequest())
    try{
        const response = await axiosClouth.post(`/api/users/changeUserType`,reqData.profileData,{
            headers:{
                "Authorization":`Bearer ${reqData.reqJWT}`
              }
        })
        const user = response.data;
        dispatch(chgUserProfileSuccess(user));
    }catch (error){
        const errorMessage = error.message;
        console.log(error)
        dispatch(chgUserProfileFailure(errorMessage));
    }

}

//  get user from token
export const getAllCustomers = (reqData) => {
    return async (dispatch) => {
      console.log("jwt - ",reqData)
      dispatch({ type: GET_ALL_USER_REQUEST });
      try {
        //const response = await axiosClouth.get(`/api/admin/users`,{
        const response = await axiosClouth.get(`/api/users/profileList?emailId=${reqData.emailId}`,{
          headers:{
            "Authorization":`Bearer ${reqData.reqJWT}`
          }
        });
        const users = response.data;
        dispatch({ type: GET_ALL_USER_SUCCESS, payload: users });
        console.log("All Customers",users)
      } catch (error) {
        const errorMessage = error.message;
        console.log(error)
        dispatch({ type: GET_ALL_USER_FAILURE, payload: errorMessage });
      }
    };
};


const getUserRequest=()=>({type:GET_USER_REQUEST});
const getUserSuccess=(user)=>({type:GET_USER_SUCCESS, payload:user});
const getUserFailure=(error)=>({type:GET_USER_FAILURE, payload:error});

export const getUser=(userData)=>async (dispatch)=>{
    //console.log("JWT511- ", localStorage.getItem("jwt"))
    const jwt=userData?.accessToken;
    dispatch(getUserRequest())
    console.log("AccessToken- ",jwt);
    console.log("Emailid Get2- ", userData?.emailid);
    try{
        const response=await axiosClouth.get(`/api/v1/auth/user`, {
            headers:{
                "Authorization": `Bearer ${jwt}`
            }
        });
        const user=response.data;
        console.log("user-",user)
        dispatch(getUserSuccess(user))
    }catch (error) {
        dispatch(getUserFailure(error.message))
    }
    
}

export const logout=(userData)=>async (dispatch)=>{
    const client = 'client';
    const secret = 'secret';
    const config =  { headers: {'Authorization': `Basic ${Buffer.from(`${client}:${secret}`).toString('base64')}`,
                        'content-type': 'application/json'},
                    };
    try {
        const APIKit_AccessToken = await axiosAuth.post("/oauth2/revoke?token="+userData?.access_token,null, config);
        const APIKit_RefreshToken = await axiosAuth.post("/oauth2/revoke?token="+userData?.refresh_token,null, config);
        const APIKit_idToken = await axiosAuth.post("/oauth2/revoke?token="+userData?.id_token,null, config);
        await axiosAuth.get('/do/logout',{
            headers:{
                'Authorization': `Bearer ${userData?.id_token}`
            }
        }).then((response)=> {
            if (response.data) {
                dispatch({type:LOGOUT, payload:null})
                window.caches.delete();
                window.sessionStorage.clear();
                localStorage.clear();
                console.log("Email Logout- ", localStorage.getItem("emailid"));
                console.log("APIKit_AccessToken-",APIKit_AccessToken.status);
                console.log("APIKit_RefreshToken-",APIKit_RefreshToken.status);
                console.log("APIKit_idToken-",APIKit_idToken.status);
                //window.location ="http://localhost:9000/do/logout";
                window.location ="https://www.enearmart.com/oauthsrvr/do/logout";
            }
        });
    } catch (error) {
        dispatch(getUserFailure(error.message))
    }

    dispatch({type:LOGOUT, payload:null})
    localStorage.clear();
}