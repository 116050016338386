import React from 'react'
import MainCarousel from '../../components/HomeCarosel/MainCarousel'
import HomeSectionCarousel from '../../components/HomeSectionCarousel/HomeSectionCarousel'
import {mens_kurta} from '../../../Data/Men/mens_kurta';
const HomePage = () => {
  console.log("JWT11- ",localStorage.getItem("jwt"));
  return (
    <div>
        <MainCarousel/>

        <div className='space-y-10 py-20 flex flex-col justify-center px-5 lg:px-10'>
          <HomeSectionCarousel data={mens_kurta} sectionName={"Men's Jeans"}/>
          <HomeSectionCarousel data={mens_kurta} sectionName={"Men's Kurta"}/>
          <HomeSectionCarousel data={mens_kurta} sectionName={"Men's Shirt"}/>
        </div>
      
    </div>
  )
}

export default HomePage
