import React from 'react'
import {Box, Modal, Typography } from '@mui/material'
import RegisterForm from './RegisterForm';
import { useLocation } from 'react-router-dom';
import VrfyForm from './VrfyForm';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  outline: "none",
  boxShadow: 24,
  p: 4,
};

const AuthModal = ({handleClose, open}) => {
    const location=useLocation();
    
    //const [open, setOpen] = React.useState(false);
    //const handleOpen = () => setOpen(true);
    //const handleClose = () => setOpen(false);

  return (
    <div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                {location.pathname==="/VrfyForm" ? <VrfyForm/> : <RegisterForm/>}
            </Box>
        </Modal>
    </div>
  )
}

export default AuthModal
