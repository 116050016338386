import React, { useEffect } from 'react'
import { Button } from '@mui/material'
import CartItem from '../Cart/CartItem'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderById } from '../../../State/Order/Action'
import { useLocation } from 'react-router-dom'
import AddressCardSelected from '../AddressCard/AddressCardSelected'
import { sendEnquiry } from '../../../State/Cart/Action'

const OrderSummary = () => {

    const {auth}=useSelector(store=>store);
    const {order}=useSelector(store=>store);
    const dispatch=useDispatch();
    const location=useLocation();
    const searchParams=new URLSearchParams(location.search);
    const orderId=searchParams.get("order_id");

    const handelSubmit=()=>{
        const data={
            reqJWT: auth?.jwt?.access_token
        }
        dispatch(sendEnquiry(data));
    }

    useEffect(()=>{

        const data={
            orderId: orderId,
            emailId:localStorage.getItem("emailid"),
            reqJWT:auth?.jwt?.access_token, 
        }
        dispatch(getOrderById(data));

    },[orderId]);

    return (
        <div>
            <div className='text-left p-5 shadow-lg rounded-s-md border'>
                <AddressCardSelected address={order?.order?.shippingAddress}/>
            </div>

            <div>

                <div className='lg:grid grid-cols-3 relative'>

                    <div className='col-span-2'>
                        {order?.order?.orderItemList?.map((item) => <CartItem item={item} />)}
                    </div>
                    <div className='px-5 sticky top-0 h-[100vh] mt-5 lg:mt-0'>
                        <div className='border'>
                            <p className='uppercase font-bold opacity-60 pb-4'>Price details</p>
                            <hr />
                            <div className='space-y-3 font-semibold mb-10'>
                                <div className='flex justify-between pt-3 text-black'>
                                    <span>Price</span>
                                    <span>₹{order?.order?.totalPrice}</span>
                                </div>
                                <div className='flex justify-between pt-3'>
                                    <span>Disccount</span>
                                    <span className='text-green-600'>-₹{order?.order?.discount}</span>
                                </div>
                                <div className='flex justify-between pt-3'>
                                    <span>Delivery Charge</span>
                                    <span className='text-green-600'>Free</span>
                                </div>
                                <div className='flex justify-between pt-3'>
                                    <span>Total Amount</span>
                                    <span className='text-green-600 font-bold'>₹{order?.order?.totalDiscountedPrice}</span>
                                </div>
                            </div>
                            <Button onClick={handelSubmit} variant="contained" className='w-full mt-5' sx={{ px: "2.5rem", py: ".7rem", bgcolor: "#9155fd" }}>
                                Checkout
                            </Button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default OrderSummary
