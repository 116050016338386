export const CREATE_ORDER_REQUEST='CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS='CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE='CREATE_ORDER_FAILURE';

export const GET_ORDER_BY_ID_REQUEST='GET_ORDER_BY_ID_REQUEST';
export const GET_ORDER_BY_ID_SUCCESS='GET_ORDER_BY_ID_SUCCESS';
export const GET_ORDER_BY_ID_FAILURE='GET_ORDER_BY_ID_FAILURE';

export const GET_ORDER_HISTORY_REQUEST='GET_ORDER_HISTORY_REQUEST';
export const GET_ORDER_HISTORY_SUCCESS='GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_FAILURE='GET_ORDER_HISTORY_FAILURE';

export const GET_ADDRESS_REQUEST="GET_ADDRESS_REQUEST";
export const GET_ADDRESS_SUCCESS="GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAILURE="GET_ADDRESS_FAILURE";