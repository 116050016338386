import { Box, Button, Grid, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import AddressCard from '../AddressCard/AddressCard'
import { useDispatch, useSelector } from 'react-redux'
import { createOrder, getAddressList } from '../../../State/Order/Action'
import { useLocation, useNavigate } from 'react-router-dom'

const DeliveryAddressForm = () => {

    const dispatch=useDispatch();
    const navigate=useNavigate();
    const {auth}=useSelector(store=>store);
    const location=useLocation();
    const searchParams=new URLSearchParams(location.search);
    const cartId=searchParams.get("cart_id");
    const {order}=useSelector(store=>store);
    
    const handelSubmit=(e)=>{
        e.preventDefault();

        const data=new FormData(e.currentTarget);

        const addressSend={
            firstName:data.get("firstName"),
            lastName:data.get("lastName"),
            streetAddress:data.get("address"),
            city:data.get("city"),
            state:data.get("state"),
            pincode:data.get("pincode"),
            mobile:data.get("phoneNumber")
        }

        const orderData={
            addressSend,
            emailId:localStorage.getItem("emailid"),
            reqJWT:auth?.jwt?.access_token, 
            navigate
        }

        dispatch(createOrder(orderData));

        console.log("äddress",orderData);
    }
    useEffect(()=>{
        const data={
            cartId: cartId,
            reqJWT: auth?.jwt?.access_token,
        }
        console.log("AddressRequested");
        dispatch(getAddressList(data));
    },[cartId]);

    return (
        <div>
            <Grid container spacing={4}>
                <Grid xs={12} lg={5} className='border rounded-e-md shadow-md h-[30.5rem] overflow-y-scroll'>

                    <div className='text-left p-5 py-7 border-b cursor-pointer'>
                       {/* {order?.addressData?.map((item)=>
                            <AddressCard address={item}/>
                        )}*/}
                        <AddressCard address={order?.addressData}/>
                        
                    </div>

                </Grid>

                <Grid item xs={12} lg={7}>

                    <Box className="border rounded-s-md shadow-md p-5">

                        <form onSubmit={handelSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField 
                                        required
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        fullWidth
                                        autoComplete="given-name"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField 
                                        required
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        fullWidth
                                        autoComplete="given-name"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        required
                                        id="address"
                                        name="address"
                                        label="Address"
                                        fullWidth
                                        autoComplete="given-name"
                                        multiline
                                        rows={4}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField 
                                        required
                                        id="city"
                                        name="city"
                                        label="City"
                                        fullWidth
                                        autoComplete="given-name"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField 
                                        required
                                        id="state"
                                        name="state"
                                        label="State/Province/Region"
                                        fullWidth
                                        autoComplete="given-name"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField 
                                        required
                                        id="pincode"
                                        name="pincode"
                                        label="Zip/Postal Code"
                                        fullWidth
                                        autoComplete="shipping postal-code"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField 
                                        required
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        label="Phone Number"
                                        fullWidth
                                        autoComplete="given-name"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <Button 
                                    sx={{ py:1.5, mt: 2, bgcolor: "RGB(145 85 253)" }} 
                                    size='large' 
                                    variant='contained'
                                    type="submit"
                                >
                                    Add New Address
                                    {/*Deliver Here*/}
                                </Button>
                                </Grid>
                            </Grid>
                        </form>

                    </Box>

                </Grid>
            </Grid>
        </div>
    )
}

export default DeliveryAddressForm

