import { 
    CHANGE_USER_PROFILE_FAILURE,
    CHANGE_USER_PROFILE_REQUEST,
    CHANGE_USER_PROFILE_SUCCESS,
    GET_ALL_USER_FAILURE,
    GET_ALL_USER_REQUEST,
    GET_ALL_USER_SUCCESS,
    GET_USER_FAILURE, 
    GET_USER_REQUEST, 
    GET_USER_SUCCESS, 
    LOGIN_FAILURE, 
    LOGIN_REQUEST, 
    LOGIN_SUCCESS, 
    LOGOUT, 
    REGISTER_FAILURE, 
    REGISTER_REQUEST, 
    REGISTER_SUCCESS, 
    VRFYACCTS_FAILURE, 
    VRFYACCTS_REQUEST,
    VRFYACCTS_SUCCESS} from "./ActionType"

const initialState={
    user:null,
    isLoading:false,
    error:null,
    jwt:null,
    vrfy:null,
    customers: []
}

export const authReducer=(state=initialState, action)=>{

    switch(action.type){
        case REGISTER_REQUEST:
        case VRFYACCTS_REQUEST:
        case LOGIN_REQUEST:
        case GET_ALL_USER_REQUEST:
        case CHANGE_USER_PROFILE_REQUEST:
        case GET_USER_REQUEST:
            return {...state, isLoading:true, error:null}

        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            return {...state, isLoading:false, error:null, jwt:action.payload}

        case VRFYACCTS_SUCCESS:
            return {...state, isLoading:false, error:null, vrfy:action.payload }
            
        case GET_USER_SUCCESS:
        case CHANGE_USER_PROFILE_SUCCESS:
            return {...state, isLoading:false, error:null, user:action.payload}

        case GET_ALL_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: action.payload
              }
              
        case REGISTER_FAILURE:
        case VRFYACCTS_FAILURE:
        case LOGIN_FAILURE:
        case GET_ALL_USER_FAILURE:
        case CHANGE_USER_PROFILE_FAILURE:
        case GET_USER_FAILURE:
            return {...state, isLoading:false, error:action.payload}

        case LOGOUT:
            return {...initialState}

        default:
            return state;
    }
}