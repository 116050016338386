import React, { useEffect } from 'react'
import CartItem from './CartItem'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { getCartItem, sendEnquiry } from '../../../State/Cart/Action';


function Cart() {
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const {auth}=useSelector(store=>store); 
    const {cart}=useSelector(store=>store);
    const handleCheckout=()=>{
        /*const data={
            reqJWT: auth?.jwt?.access_token,
            cartId: cart?.cart?.cartId
        }
        console.log(data);

        dispatch(sendEnquiry(data)).then(()=>{
            navigate("/checkout?step=2");
            //console.log("Success");
        });*/
        navigate(`/checkout?step=2&cart_id=${cart?.cart?.cartId}`);

    }

    useEffect(()=>{
        const data={
            reqJWT: auth?.jwt?.access_token
        }
        dispatch(getCartItem(data));
        console.log("Cart Item Getted in Cart User-",cart);
    },[cart?.cart?.cartId, cart.updateCartItem, cart.deleteCartItem]);

  return (
    <div>

        <div className='lg:grid grid-cols-3 lg:px-16 relative'>

            <div className='col-span-2'>
              {cart?.cart?.cartItems?.map((item)=><CartItem item={item}/>)}
            </div>
             <div className='px-5 sticky top-0 h-[100vh] mt-5 lg:mt-0'>
                <div className='border'>
                    <p className='uppercase font-bold opacity-60 pb-4'>Price details</p>
                    <hr/>
                    <div className='space-y-3 font-semibold mb-10'>
                        <div className='flex justify-between pt-3 text-black'>
                            <span>Price</span>
                            <span>₹{cart?.cart?.totalPrice}</span>
                        </div>
                        <div className='flex justify-between pt-3'>
                            <span>Disccount</span>
                            <span className='text-green-600'>-₹{cart?.cart?.discount}</span>
                        </div>
                        <div className='flex justify-between pt-3'>
                            <span>Delivery Charge</span>
                            <span className='text-green-600'>Free</span>
                        </div>
                        <div className='flex justify-between pt-3'>
                            <span>Total Amount</span>
                            <span className='text-green-600 font-bold'>₹{cart?.cart?.totalDiscountedPrice}</span>
                        </div>
                    </div>
                    <Button onClick={handleCheckout} variant="contained" className='w-full mt-5' sx={{px:"2.5rem", py:".7rem", bgcolor:"#9155fd"}}>
                        {/*Checkout*/}
                        Enquiry
                    </Button>  
                </div>
            </div>

        </div>
       
    </div>
  )
}

export default Cart
