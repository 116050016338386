import React, { useState } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createOrder, createOrderWithOldAddress } from '../../../State/Order/Action';

const AddressCard = ({address}) => {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const {auth}=useSelector(store=>store);
  const [selectedValue, setSelectedValue]=useState(0);

  const onChange=(e,val)=>{
    setSelectedValue(val);
  }
  const handelSubmit=(e)=>{
    e.preventDefault();

    const filteredAddress = address.filter(item=> item.addressId == selectedValue);
      //let addressSend=Object.assign({},filteredAddress);
      const orderData={
        filteredAddress,
        emailId:localStorage.getItem("emailid"),
        reqJWT:auth?.jwt?.access_token, 
        navigate
    }

    dispatch(createOrderWithOldAddress(orderData));

    console.log("äddress",orderData);
  }
  return (
    
    <div className='space-y-3'>
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Pickup Address</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={onChange}
      >
        {address?.map((item)=>
          <>
            <FormControlLabel value={item?.addressId} control={<Radio />} label={
                <>
                  <p className='font-semibold'>{item?.firstName+" "+item?.lastName}</p>
                  <p>{"At-"}{item?.streetAddress}</p>
                  <p>{"District-"}{item?.city}{"-"}{item?.pincode}</p>
                  <p>{"State-"}{item?.state}</p>
                  <div className='space-y-1'>
                    <p className='font-semibold'>Phone Number</p>
                    <p>{item?.mobile}</p>
                  </div>
                  <p>-----------------------------------------------</p>
              </>}
            >
            </FormControlLabel>
          </>
        )}
      </RadioGroup>
      <Button 
        sx={{ mt: 2, bgcolor: "RGB(145 85 253)" }} 
        size='large' 
        variant='contained'
        onClick={handelSubmit}
      >
        Deliver Here
      </Button>
    </FormControl>
    </div>
    
  )
}

export default AddressCard
