import { useEffect, useState } from "react";
//import { useRouter, useSearchParams } from "next/navigation";
import { Buffer } from "buffer";
import { useNavigate, useSearchParams } from "react-router-dom";
import Authorize from "../../State/Auth/Authorize";
import { useDispatch, useSelector } from "react-redux";
import { getUser, login } from "../../State/Auth/Action";
import { axiosAuth, axiosClouth, mainAxios } from "../../config/apiConfig";
//import {signIn, useSession } from "next-auth/react";

const AuthForm = () => {
    const [searchParamsCode, setSearchParamsCode] = useSearchParams();
    const [searchParamsVrfy, setSearchParamsVrfy] = useSearchParams();
    //const router = useRouter();
    const navigate=useNavigate();
    const [data, setData] = useState('Test');
    //const {data: session, status} = useSession();
    const [status, setStatus] = useState("unauthenticated");
    const dispatch = useDispatch(); 
    const {auth}=useSelector(store=>store)
    var user = null;
    useEffect(()=>{
      //console.log("Started");
      async function fetchData() {
        //console.log("Started22-", searchParamsCode.get('code'));
        if(searchParamsCode?.get('code')){ 
          const code = searchParamsCode?.get('code');
          sessionStorage.setItem('code',code);
          const state = searchParamsVrfy.get('state');
          console.log("Code- ",code);
          console.log("State- ",state);
          console.log("Started21- ",status);
          if(status==='unauthenticated'){
            console.log("Started23"); 
            const response = await mainAxios.post("/api/v1/auth/getCodeVrfy",{
              stateKey: state,
            });
            //console.log("Started2"); 
            const codeVrfy = response.data;
            //console.log("Code-1001-",codeVrfy.codeVrfyKey);
            const client = 'admin';
            const secret = 'secret';
            const config = { headers: {'Authorization': `Basic ${Buffer.from(`${client}:${secret}`).toString('base64')}`,
                                'content-type': 'application/json'},
                              };
            if(codeVrfy){ 
              //const res = await axiosAuth.post(`/oauth2/token?client_id=admin&redirect_uri=http://127.0.0.1:3000/admin/auth&grant_type=authorization_code&code=${code}&code_verifier=${codeVrfy.codeVrfyKey}`,null, config);
              const res = await axiosAuth.post(`/oauth2/token?client_id=admin&redirect_uri=https://www.enearmart.com/admin/auth&grant_type=authorization_code&code=${code}&code_verifier=${codeVrfy.codeVrfyKey}`,null, config);
              //console.log("Res-",res.status);
              user = res.data;
              console.log('AccessToken-', user);
              //console.log('AccessToken-', user.access_token);
              //localStorage.setItem("jwt11",user.access_token);
              if(user){
                const userres = await axiosAuth.post('/oauth2/introspect?token='+user.access_token,null,config);
                /*const userres=await axiosClouth.get(`/api/v1/auth/user?emailid=nikeesinha22@gmail.com`, {
                  headers:{
                      "Authorization": `Bearer ${user.access_token}`
                  }
                })*/
                const user2 = userres.data;
                //console.log("Emailid-",user2);
                //console.log("Emailid-",user2.sub);
                //console.log("Started3");
                if(user2){
                  localStorage.setItem("emailid",user2.sub);
                  console.log("Emailid Passing- ",localStorage.getItem("emailid"));
                  dispatch(login(user));                  
                  navigate("/admin")
                  
                  return {
                    email: user2.sub,
                    //accessTokenExpires: Date.now() + user.expires_in, 
                    accessToken: user.access_token, 
                    refreshToken: user.refresh_token,
                    idToken: user.id_token, 
                  };
                }else{
                  return null;
                }
              }else{
                return null;
              }
            }else{
              throw new Error("Error");
            }
          }else{
            navigate("/")
          }
          
        }else if(!searchParamsCode?.get('code')){
          //const codeVrfy = sessionStorage.getItem('codeVerifier');
          //console.log('redirect ===', codeVrfy);
          window.location.href = Authorize();
        }
      }
      fetchData();
    },[]);

    const result="authenticated";
    useEffect(()=>{
      setStatus(result)
    },[]);
    //setStatus(preStatus=>([...preStatus, ...result]));
    console.log("Status- ",status);

    return <p>Redirecting for authorization...!</p>
}

export default AuthForm;