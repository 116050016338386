import * as crypto from 'crypto-js';

const base64Url = (str) => {
    return str.toString(crypto.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

const generateCodeVerifer = () => {
    const codeVerifier = base64Url(crypto.enc.Base64.stringify(crypto.lib.WordArray.random(32)));
    return codeVerifier;
}

const generateCodeChallenge = () => {
    const codeVerifier = localStorage.getItem('code_Verifier');
    return base64Url(crypto.SHA256(codeVerifier));
}

const authProvider = () => {
    
}

export {
    base64Url,
    generateCodeVerifer,
    generateCodeChallenge,
    authProvider
}