import axios from "axios";

//export const API_BASE_URL="http://localhost:8080"

/*const BASE_URL_SECURITY = "http://localhost:8080/securitysrvr";
const BASE_URL_CLIENT = "http://localhost:8181/clientsrvr";
const BASE_URL_OAUTH = "http://localhost:9000/oauthsrvr";
const BASE_URL_CLOUTH = "http://localhost:9090/clouthsrvr";*/

const BASE_URL_SECURITY = "https://www.enearmart.com/securitysrvr";
const BASE_URL_CLIENT = "https://enearmart.com/clientsrvr";
const BASE_URL_OAUTH = "https://www.enearmart.com/oauthsrvr";
const BASE_URL_CLOUTH = "https://www.enearmart.com/clouthsrvr";

const mainAxios = axios.create({

    baseURL:BASE_URL_SECURITY,

    headers:{"Content-Type": "application/json"},
    
});


const axiosAuth = axios.create({

    baseURL:BASE_URL_OAUTH,

    //headers:{"Content-Type": "application/json"},
    
});

const axiosClient = axios.create({

    baseURL:BASE_URL_CLIENT,

    headers:{"Content-Type": "application/json"},
    
});

const axiosClouth = axios.create({
    
    baseURL:BASE_URL_CLOUTH,
    
    headers:{"Content-Type": "application/json"},
    
});

export {
    mainAxios,
    axiosAuth,
    axiosClient,
    axiosClouth
}