import React from "react";
import { useState } from "react";
import { Typography } from "@mui/material";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Fragment } from "react";
import "./BusinessProfileForm.css";
import { useDispatch, useSelector } from "react-redux";
import { chgUserProfile } from "../../../State/Auth/Action";

const BusinessProfileForm = () => {
  const [profileData, setProfileData] = useState({
    emailId: "",
    gstno: "",
    panno: "",
    tanno: "",
    tradeName: "",
    proprietorName: "",
    propHomeAddress: "",
    businessType: "",
    businessAddress: "",
    businessCity: "",
    businessDistrict: "",
    businessState: "",
    phoneNo: "",
    mobileNo: "",
    mobileNoSecondary: "",
    mobileNoWhatsApp: "",
  });
  const dispatch = useDispatch();
  const { auth } = useSelector((store) => store);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      profileData,
      reqJWT: auth?.jwt?.access_token,
    };
    dispatch(chgUserProfile(data));
    console.log(profileData);
  };

  return (
    <Fragment className="createProductContainer ">
      {(auth?.user?.firstname && (auth?.user?.role==='ADMIN' || auth?.user?.role==='MANAGER')) ? (
      <Typography
        variant="h3"
        sx={{ textAlign: "center" }}
        className="py-10 text-center "
      >
        Profile Page
      </Typography>
      ):(<div>Not Login</div>)}
      {(auth?.user?.firstname && (auth?.user?.role==='ADMIN' || auth?.user?.role==='MANAGER')) ? (
      <form
        onSubmit={handleSubmit}
        className="createProductContainer min-h-screen"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email Id"
              name="emailId"
              value={profileData.emailId}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="GST Number"
              name="gstno"
              value={profileData.gstno}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="TAN Nubmer"
              name="tanno"
              value={profileData.tanno}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="PAN Number"
              name="panno"
              value={profileData.panno}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Trade Name"
              name="tradeName"
              value={profileData.tradeName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Proprietor Name"
              name="proprietorName"
              value={profileData.proprietorName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Proprietor Home Address"
              name="propHomeAddress"
              value={profileData.propHomeAddress}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Business Type</InputLabel>
              <Select
                name="businessType"
                value={profileData.businessType}
                onChange={handleChange}
                label="Business Type"
              >
                <MenuItem value="Proprietor">Proprietor</MenuItem>
                <MenuItem value="Farm">Farm</MenuItem>
                <MenuItem value="Company">Company</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              fullWidth
              label="Business Address"
              name="businessAddress"
              value={profileData.businessAddress}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              fullWidth
              label="Business City"
              name="businessCity"
              value={profileData.businessCity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Business District</InputLabel>
              <Select
                name="businessDistrict"
                value={profileData.businessDistrict}
                onChange={handleChange}
                label="Business District"
              >
                {/*<MenuItem value="Deoghar">Deoghar</MenuItem>*/}
                <MenuItem value="Giridih">Giridih</MenuItem>
                {/*<MenuItem value="Dhanbad">Dhanbad</MenuItem>*/}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Business State</InputLabel>
              <Select
                name="businessState"
                value={profileData.businessState}
                onChange={handleChange}
                label="Business State"
              >
                {/*<MenuItem value="Bihar">Bihar</MenuItem>*/}
                <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                {/*<MenuItem value="UP">Utter Pradesh</MenuItem>*/}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNo"
              value={profileData.phoneNo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              fullWidth
              label="Mobile Number"
              name="mobileNo"
              value={profileData.mobileNo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              fullWidth
              label="Other Mobile Number"
              name="mobileNoSecondary"
              value={profileData.mobileNoSecondary}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} sm={8}>
            <TextField
              fullWidth
              label="Business WhatsApp Mobile Number"
              name="mobileNoWhatsApp"
              value={profileData.mobileNoWhatsApp}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ p: 0.7 }}
              color="primary"
              size="large"
              type="submit"
            >
              Save Profile
            </Button>
          </Grid>
        </Grid>
      </form>
      ):(<div></div>)}
    </Fragment>
  );
};

export default BusinessProfileForm;
