import { axiosClouth } from "../../config/apiConfig";

import {
  ADD_ITEM_TO_CART_FAILURE,
  ADD_ITEM_TO_CART_REQUEST,
  ADD_ITEM_TO_CART_SUCCESS,
  CREATE_CART_FAILURE,
  CREATE_CART_REQUEST,
  CREATE_CART_SUCCESS,
  GET_CART_FAILURE,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  REMOVE_CART_ITEM_FAILURE,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS,
  SEND_WHATSAPP_FAILURE,
  SEND_WHATSAPP_REQUEST,
  SEND_WHATSAPP_SUCCESS,
  UPDATE_CART_ITEM_FAILURE,
  UPDATE_CART_ITEM_REQUEST,
  UPDATE_CART_ITEM_SUCCESS,

} from "./ActionType";

export const getCartItem = (reqData) => async (dispatch) => {

  dispatch({ type: GET_CART_REQUEST });
  console.log("AccessToken101-",reqData.reqJWT);
  try {
    const { data } = await axiosClouth.get(`api/cart/`, {
      headers: {
        Authorization: `Bearer ${reqData.reqJWT}`,
      },
    });
    dispatch({ type: GET_CART_SUCCESS, payload: data });
    console.log("Cart- ",data);
  } catch (error) {
    dispatch({ type: GET_CART_FAILURE, payload: error.message });
  }
};

export const createCart = (reqData) => async (dispatch) => {

  dispatch({type: CREATE_CART_REQUEST});
  console.log("Accesstoken201- ", reqData.reqJWT);
  try{
    const {data} =  await axiosClouth.put(`api/cart/createCart`,null,{
      headers: {
        Authorization: `Bearer ${reqData.reqJWT}`,
      },
    });
    console.log("Create Cart- ",data);
    dispatch({type: CREATE_CART_SUCCESS, payload: data});

  }catch(error) {
    dispatch({type: CREATE_CART_FAILURE, payload: error.message});
  }
};

export const addItemtoCart = (reqData) => async (dispatch) => {
 
  dispatch({ type: ADD_ITEM_TO_CART_REQUEST });
  console.log(reqData);
  try {
    const { data } = await axiosClouth.put(`api/cart/add`, reqData, {
      headers: {
        Authorization: `Bearer ${reqData.reqJWT}`,
      },
    });
    console.log("Add Item To Cart- ",data);
    dispatch({ type: ADD_ITEM_TO_CART_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ADD_ITEM_TO_CART_FAILURE, payload: error.message });
  }
};

export const removeCartItem = (reqData) => async (dispatch) => {

  dispatch({ type: REMOVE_CART_ITEM_REQUEST });
  console.log("Item Id- ",reqData.cartItemId);
  try {
    const { data } = await axiosClouth.delete(
      `api/cart_items/delete/${reqData.cartItemId}`,
      {
        headers: {
          Authorization: `Bearer ${reqData.reqJWT}`,
        },
      }
    );
    dispatch({ type: REMOVE_CART_ITEM_SUCCESS, payload: reqData.cartItemId });
    console.log("Deleted",data);
  } catch (error) {
    dispatch({ type: REMOVE_CART_ITEM_FAILURE, payload: error.message });
  }
};

export const updateCartItem = (reqData) => async (dispatch) => {
 
  dispatch({ type: UPDATE_CART_ITEM_REQUEST });

  try {
    const { data } = await axiosClouth.put(
      `api/cart_items/update/${reqData.cartItemId}`,
      reqData.cartItem,
      {
        headers: {
          Authorization: `Bearer ${reqData.reqJWT}`,
        },
      }
    );
    dispatch({ type: UPDATE_CART_ITEM_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_CART_ITEM_FAILURE, payload: error.message });
  }
};

export const sendEnquiry = (reqData) => async (dispatch) =>{

    dispatch({type: SEND_WHATSAPP_REQUEST});

    try{
        const { data } = await axiosClouth.post(`/api/v1/mrkt/sendWhatsApp`,null,{
          headers: {
            Authorization: `Bearer ${reqData.reqJWT}`,
          },
        });
        
        dispatch({type: SEND_WHATSAPP_SUCCESS, payload: data});
    }catch(error){
      dispatch({type: SEND_WHATSAPP_FAILURE, payload: error.message});
    }
};
