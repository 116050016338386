import React from 'react';
import "./ProductCard.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createCart } from '../../../State/Cart/Action';
const ProductCard = ({ product }) => {
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const {auth}=useSelector(store=>store);
    
    const handelCreateCart=()=>{
        const data={
            reqJWT: auth?.jwt?.access_token
        }
        dispatch(createCart(data));
        navigate(`/product/${product?.productId}`);
    };

    return (
        <div onClick={handelCreateCart} className='productCard w-[15rem] m-3 transition-all cursor-pointer'>
            <div className='h-[20rem]'>
                <img className='h-full w-full object-cover object-left-top' src={product.imageUrl}
                    alt="" />
            </div>

            <div className='textPart bg-white p-3'>
                <div>
                    <p className='font-bold opacity-60'>{product.brand}</p>
                    <p>{product.title}</p>
                </div>
            </div>

            <div className='flex items-center space-x-2'>
                <p className='font-semibold'>{product.discountedPrice}</p>
                <p className='line-through opacity-60'>{product.price}</p>
                <p className='text-green-600 font-semibold'>{product.discountPersent}% off</p>
            </div>
        </div>
    )
}

export default ProductCard
