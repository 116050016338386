import React, { useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import HomeSectionCard from '../HomeSectionCard/HomeSectionCard';
import { Button } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import 'react-alice-carousel/lib/alice-carousel.css';

const HomeSectionCarousel = ({data,sectionName}) => {
    const [activeIndex,setActiveIndex]=useState(0);
    const carouselRef = useRef(null);

    const responsive = {
        0: { items: 1 },
        720: { items: 3 },
        1024: { items: 5 },
        1280: { items: 6 },
    };

    const items = data.slice(0,10).map((item) => <HomeSectionCard product={item} />);
    const maxIndex = items.length - 1;

    const slidePrev=()=>{
        if (activeIndex > 0) {
            const newIndex = activeIndex - 1;
            setActiveIndex(newIndex);
            carouselRef.current.slideTo(newIndex);
        }
    };

    const slideNext=()=>{
        if (activeIndex < maxIndex) {
            const newIndex = activeIndex + 1;
            setActiveIndex(newIndex);
            carouselRef.current.slideTo(newIndex);
        }
    };

    return (
        <div className="border">
            <h2 className="text-2xl font-extrabold text-gray-800 py-5">{sectionName}</h2>
            <div className="relative p-5">
                <AliceCarousel
                    items={items} 
                    disableDotsControls
                    disableButtonsControls
                    responsive={responsive}
                    activeIndex={activeIndex}
                    ref={carouselRef}
                />
                {activeIndex !== items.length-5 && (<Button 
                    variant="contained" 
                    className="z-50 bg-white" 
                    onClick={slideNext}
                    sx={{
                        position: "absolute", 
                        top: "8rem", 
                        right: "0rem",
                        transform: "translateX(50%) rotate(90deg)", 
                        bgcolor: "white"
                }} 
                aria-label="next">
                    <KeyboardArrowLeftIcon sx={{ transform: "rotate(90deg)", color: "black" }} />
                </Button>)}

                {activeIndex !==0 && (<Button 
                    variant="contained" 
                    className="z-50 bg-white" 
                    onClick={slidePrev}
                    sx={{
                        position: "absolute", 
                        top: "8rem",
                        left: "0rem", 
                        transform: "translateX(-50%) rotate(-90deg)", 
                        bgcolor: "white"
                    }} 
                    aria-label="next">
                    <KeyboardArrowLeftIcon sx={{ transform: "rotate(90deg)", color: "black" }} />
                </Button>)}
            </div>
        </div>
    );
};

export default HomeSectionCarousel
